.load {
    height: 100vh;
    background-color: rgb(255, 255, 255);
}
.load .bg{
    align-items: center;
    justify-content: center;
    display: flex;
    backdrop-filter:blur(3px);
    height: 100vh;
}
.load .bg span {
    font-size: 50px;
    font-weight: bolder;
}

@keyframes arrows {

    0%,
    100% {
        color: black;
        transform: translateY(0);
    }

    50% {
        color: #3AB493;
        transform: translateY(20px);
    }
}

.load span:nth-child(1) {
    animation: arrows 1s 0s infinite ease-in;
}

.load span:nth-child(2) {
    animation: arrows 1s 0.1s infinite ease-in;
}

.load span:nth-child(3) {
    animation: arrows 1s 0.2s infinite ease-in;
}

.load span:nth-child(4) {
    animation: arrows 1s 0.3s infinite ease-in;
}

.load span:nth-child(5) {
    animation: arrows 1s 0.4s infinite ease-in;
}

.load span:nth-child(6) {
    animation: arrows 1s 0.5s infinite ease-in;
}

.load span:nth-child(7) {
    animation: arrows 1s 0.6s infinite ease-in;
}

.load span:nth-child(8) {
    animation: arrows 1s 0.7s infinite ease-in;
}

.load span:nth-child(9) {
    animation: arrows 1s 0.8s infinite ease-in;
}

.load span:nth-child(10) {
    animation: arrows 1s 0.9s infinite ease-in;
}